@charset "utf-8";

// @import '~animate.css/animate';

@mixin sm {
  @media screen and (max-width: 768px) {
    @content
  }
};

@mixin lg {
  @media screen and (max-width: 992px) {
    @content
  }
};
.pc-only {
  @include sm {
    display: none;
  }
}
.sp-only {
  display: none;
  @include sm {
    display: block;
  }
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  color: #222;
  padding: 0;
  margin: 0;
  &.fixed {
    height: 100vh;
    overflow: hidden; 
  }
}
h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}
a {
  display: inline-block;
  text-decoration: none;
}
figure {
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
}
ul, ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}
input[type=text], input[type=email], textarea {
  border: none;
  outline: none;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
}
header {
  padding: 15px 0;
  border-top: 14px solid #ffcc00;
}
.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  .logo {
    img {
      height: 32px;
    }
  }
}
.bold {
  font-weight: bold !important;
}
footer {
  text-align: center;
  padding: 30px;
  margin-top: 130px;
  border-bottom: 35px solid #ffcc00;
}
.text-center {
  text-align: center !important;
}
.fixed-container {
  width: 95%;
  max-width: 1000px;
  margin: auto;
}
.footer-logo {
  margin-bottom: 30px;
  div:first-child {
    margin-bottom: 15px;
  }
  .addr {
    margin-bottom: 3px;
  }
}
.login-container {
  width: 95%;
  max-width: 415px;
  padding: 100px 0;
  margin: auto;
  @include sm {
    padding: 70px 0 80px;
  }
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  > div {
    flex: 1;
  }
  @include sm {
    > div {
      flex: 100%
    }
  }
}
.form-control-label {
  font-size: 18px;
  padding-top: 5px;
}
input.form-control {
  width: 100%;
  font-size: 16px;
  padding: 10px 12px;
  border: 1px solid #707070;
}
.login-form {
  .form-control-label {
    width: 135px;
  }
  @include sm {
    max-width: 280px;
    margin: auto;
  }
}
.login-btn-wrapper {
  margin-top: 50px;
}
.btn {
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  padding: 0 34px;
  background-color: #000;
  border-radius: 999px;
}
.login-msg {
  text-align: center;
  padding: 14px 15px;
  margin-top: 50px;
  background-color: #f2f2f2;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 4px;
  font-size: 15px;
  color: #dc3545;
}
nav {
  @include sm {
    display: none;
    position: fixed;
    top: 82px;
    left: 0;
    width: 100%;
    height: calc(100vh - 82px);
    padding: 40px 0 52px;
    background-color: #fffae9;
    z-index: 100;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 12px;
      background-color: #ffcc00;
    }
  }
}
.menu-list {
  display: flex;
  flex-wrap: wrap;
  > li {
    font-size: 18px;
    padding: 5px 20px;
    &:last-child {
      padding-right: 0;
    }
    a {
      color: #222;
    }
    @include sm {
      flex: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
.btn-logout {
  font-size: 16px;
  line-height: 26px;
  color: #fff !important;
  padding: 0 30px;
}
.d-none {
  display: none !important;
}
.user-home {
  padding: 90px 0 0;
}
.user-info {
  overflow: hidden;
  margin-bottom: 70px;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -12px -30px;
    > li {
      padding: 12px 30px;
      label {
        display: inline-block;
        width: 100px;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        background-color: #000;
        border-radius: 999px;
      }
      span {
        display: inline-block;
        padding-left: 24px;
      }
      @include sm {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}
.sitemap {
  overflow: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px;
    @include sm {
      margin: -10px;
    }
    > li {
      flex: 33.33%;
      max-width: 33.33%;
      padding: 10px 20px;
      a {
        width: 100%;
        font-size: 20px;
        color: #222;
        text-align: center;
        padding: 25px 0 22px;
        background-color: #fffdcc;
        border: 1px solid #ffcc00;
        border-radius: 9px;
        h3 {
          font-weight: normal;
          margin-top: 18px;
        }
      }
      @include sm {
        flex: 50%;
        max-width: 50%;
        padding: 10px;
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
.menu-btn {
  position: absolute;
  right: 0;
  top: -6px;
  width: 46px;
  height: 46px;
  padding: 10px 8px;
  background-color: #ffcc00;
  .menu-inner {
    position: relative;
    width: 30px;
    height: 26px;
    top: 11.5px;
    transition-duration: 0.5s;
    .icon-left,
    .icon-right {
      transition-duration: 0.5s;
      position: absolute;
      height: 3px;
      width: 15px;
      top: 0;
      background-color: #fff;
      &:before,
      &:after {
        transition-duration: 0.5s;
        position: absolute;
        width: 15px;
        height: 3px;
        background-color: #fff;
        content: "";
      }
      &:before {
        top: -11.5px;
      }
      &:after {
        top: 11.5px;
      }
    }
    .icon-left {
      left: 0;
    }
    .icon-right {
      left: 13px;
    }
  }
  &.open {
    .icon-left,
    .icon-right {
      transition-duration: 0.5s;
      background: transparent;
    }
    .icon-left:before {
      transform: rotateZ(45deg) scaleX(1.4) translate(3.5px, 3.5px);
    }
    .icon-left:after {
      transform: rotateZ(-45deg) scaleX(1.4) translate(3.5px, -3.5px);
    }
    .icon-right:before {
      transform: rotateZ(-45deg) scaleX(1.4) translate(-3.5px, 3.5px);
    }
    .icon-right:after {
      transform: rotateZ(45deg) scaleX(1.4) translate(-3.5px, -3.5px);
    }
  }
}
.menu-btn {
  cursor: pointer;
}
#pageTop {
  position: fixed;
  right: 0;
  bottom: -200px;
  width: 50px;
  height: 50px;
  background-color: #222;
  transition: .5s all;
  &.is-show {
    bottom: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 11px;
    left: 13px;
    border-bottom: 24px solid #fff;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
  }
}
.breadcrumbs {
  font-size: 14px;
  margin: 45px 0 60px;
  a {
    position: relative;
    color: #222;
    text-decoration: underline;
    margin-right: 40px;
    &:after {
      content: '';
      position: absolute;
      right: -30px;
      top: 3px;
      width: 11px;
      height: 13px;
      background: url(/images/icon_bread.png) no-repeat center;
    }
  }
}
.page-title {
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 10px;
  span {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    margin-left: 24px;
    background-color: #222;
    border-radius: 999px;
    vertical-align: middle;
  }
}
.update-date {
  margin-bottom: 15px;
}
.attendance-info {
  padding: 24px 20px;
  background-color: #F2F2F2;
  overflow: hidden;
  @include sm {
    padding: 30px 15px 35px;
  }
  .inner-block {
    display: flex;
    flex-wrap: wrap;
    margin: -15px -25px;
    > div {
      flex: 50%;
      max-width: 50%;
      padding: 15px 25px;
      @include sm {
        flex: 100%;
        max-width: 100%;
      }
    }
    .mark-info {
      display: flex;
      margin-bottom: 16px;
      .label-white {
        width: 100px;
      }
      .label-txt {
        padding-left: 15px;
        > div {
          margin-bottom: 8px;
        }
      }
      .mark {
        position: relative;
        display: inline-block;
        width: 75px;
        line-height: 27px;
        text-align: center;
        padding-left: 18px;
        &.mark01, &.mark02 {
          background-color: #ffec46;
          &:before {
            content: '';
            position: absolute;
            left: 11px;
            top: calc(50% - 12px);
            display: inline-block;
            width: 14px;
            height: 21px;
            background: url(/images/dir_top.png) no-repeat center;
            background-size: contain;
          }
        }
        &.mark02:before {
          top: calc(50% - 10px);
          transform: rotate(180deg);
        }
        &.mark03 {
          color: #fff;
          background-color: #e6122f;
          &:before {
            content: '×';
            position: absolute;
            left: 13px;
          }
        }
      }
    }
    .time-info {
      > li {
        display: flex;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .time-txt {
          padding-left: 30px;
        }
      }
    }
  }
}
.label-white {
  display: inline-block;
  width: 70px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  background-color: #fff;
  border-radius: 999px;
}
.bg-grayyellow {
  background-color: #fffdcc !important;
}
.subject-info-wrapper {
  position: relative;
  max-width: 100%;
  margin-top: 80px;
  .-header {
    margin-bottom: 10px;
    span {
      position: relative;
      display: inline-block;
      line-height: 30px;
      background-color: #ffcc00;
      padding: 0 50px 0 10px;
      &:after {
        content: '';
        position: absolute;
        left: 100%;
        bottom: 0;
        border-left: 30px solid #ffcc00;
        border-top: 30px solid transparent;
      }
    }
  }
}
.subject-table-wrapper {
  overflow-x: scroll;
  margin-top: 2px;
}
.subject-info-table {
  width: max-content;
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid #222;
  th, td {
    padding: 8px 15px;
  }
  th {
    position: sticky;
    left: 0;
    top: auto;
    min-width: 135px;
    font-weight: normal;
    text-align: left;
    background-color: #fff;
    padding: 8px 10px;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
    border-right: 3px solid #222;
    z-index: 1;
    label {
      font-weight: bold;
    }
    span {
      font-size: 13px;
    }
  }
  td {
    min-width: 175px;
    text-align: center;
    vertical-align: top;
    border-bottom: 1px solid #222;
    border-right: 1px solid #222;
    @include sm {
      width: 165px;
      padding: 8px 10px;
    }
  }
  tr:last-child td {
    text-align: left;
    > div:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .mark {
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 0 8px;
    background-color: #ffec46;
    &.mark-x {
      color: #fff;
      background-color: #e6122f;
    }
    &:after {
      display: inline-block;
      width: 16px;
      text-align: center;
      margin-left: 12px;
    }
    &.mark-top:after {
      content: '▲';
    }
    &.mark-bot:after {
      content: '▼';
    }
    &.mark-x:after {
      content: '×';
    }
  }
}
.align-top {
  vertical-align: top;
}
.absence-info-wrapper {
  display: flex;
  margin: 40px 0 0;
  .-label {
    label {
      display: inline-block;
      width: 100px;
      color: #fff;
      text-align: center;
      background-color: #222;
      border-radius: 999px;
    }
  }
  .-info {
    padding-left: 20px;
    .absence-dates-ul {
      > li {
        display: flex;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        span:first-child {
          display: inline-block;
          width: 85px;
        }
        p {
          flex: 1;
        }
      }
    }
  }
}
.scroll {
  overflow: auto;
}
.report-link-wraper {
  margin: 60px 0 0;
  overflow: hidden;
  .report-link-ul {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -15px;
    > li {
      flex: 50%;
      max-width: 50%;
      padding: 20px 15px;
      @include sm {
        flex: 100%;
        max-width: 100%;
      }
      a {
        width: 100%;
        font-size: 27px;
        line-height: 120px;
        text-align: center;
        color: #222;
        background-color: #fffdcc;
        border: 1px solid #ffcc00;
        border-radius: 9px;
      }
    }
  }
}
.table-group {
  margin-top: 50px;
  h2 {
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .table-wrapper {
    overflow: auto;
    table {
      border-collapse: collapse;
      th, td {
        padding: 8px 10px;
        border: 1px solid #222;
      }
      th {
        background-color: #fffdcc;
      }
      tr {
        th:first-child, td:first-child {
          text-align: left;
        }
      }
    }
  }
}
.w-165 {
  width: 165px !important;
}
.label-black {
  display: inline-block;
  width: 100px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  background-color: #222;
  border-radius: 999px;
}
.report-absence-info-wrapper {
  display: flex;
  align-items: center;
  margin: 15px 0 40px;
}
.report-absence-info {
  display: inline-block;
  padding-left: 18px;
}
.note-wrapper {
  padding: 25px 20px;
  background-color: #f2f2f2;
  h2 {
    margin-bottom: 14px;
  }
  .label-white {
    width: 130px;
  }
}
.multiline {
  white-space: pre-line !important;
}
.text-center {
  text-align: center !important;
}
.table-group-practice {
  margin-top: 30px;
  table {
    text-align: center;
    min-width: 460px;
    th {
      text-align: center !important;
    }
    tbody tr td:first-child {
      text-align: left;
    }
  }
}
.top-scroll-wrapper {
  height: 20px;
  overflow: auto;
}